import React from "react";
import styled from "styled-components";
import UnderConstruction from "../images/UnderConstruction.png";

const Artlab = () => {
  return (
      <img src={UnderConstruction} style={{width:"70%"}}/>
  );
};

export default Artlab;
